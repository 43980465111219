<template>
  <div id="journeys-section" class="country-infos">
    <div class="top">
      <SectionTitle class="title">{{
        isSearch ? 'Résultat de votre recherche' : 'Choisissez votre itinéraire'
      }}</SectionTitle>
    </div>
    <SectionJourneysBlockInteractiveMapContinents
      v-if="homepage"
      v-model:continentSelected="selectedContinent"
      :continents="continents"
      :continent="selectedContinent"
    />
    <!-- eslint-disable vue/v-on-event-hyphenation -->
    <mv-continents-selector
      v-if="breakpoints.isMobile && homepage"
      style="width: 100%"
      brand="mv"
      :continent="selectedContinent?.attributes.slug"
      @continentSelected="chooseContinent($event)"
    ></mv-continents-selector>

    <div class="infos-filter">
      <span v-if="homepage && selectedContinent" class="infos-journey"
        >{{ journeys.length > 0 ? journeys.length : 'Aucun' }} itinéraires en
        {{ selectedContinent.attributes.name }}</span
      >
      <span v-else-if="isWhereToGo" class="infos-journey"
        >{{ journeys.length > 0 ? journeys.length : 'Aucun' }} itinéraires correspondants</span
      >
      <span v-else class="infos-journey"
        >{{ journeys.length > 0 ? journeys.length : 'Aucun' }} itinéraire{{
          journeys.length > 1 ? 's' : ''
        }}
        {{ itineraryName }}</span
      >
      <div class="filters">
        <Dropdown class="filter filter-left">
          <template #wrapper="{ opened }">
            <button :class="{ clicked: opened }" class="filter-button">
              Filtrer par
              <Icon class="logo" name="ico-filter" />
            </button>
          </template>

          <template #content="{ toggle }">
            <div class="content">
              <div v-for="filter of BLOCK_JOURNEY_FILTERS" :key="filter.name" class="filter-name">
                <span class="name-value">{{ filter.name }}</span>
                <div v-for="(filtervalue, index) of filter.filterValues" :key="filtervalue.name">
                  <input
                    :id="filter.name + index"
                    :key="filter.name + index"
                    v-model="checkedFilters"
                    :name="filter.name + index"
                    :value="{
                      val: filtervalue.value,
                      label: filter.name,
                      name: filtervalue.name
                    }"
                    type="checkbox"
                  />
                  <label :for="filter.name + index" class="filter-name-value">{{
                    filtervalue.name
                  }}</label>
                </div>
              </div>

              <div class="filter-active-button">
                <button class="filter-reset" @click="checkedFilters = []">
                  <Icon name="arrow-circle" />
                  Réinitialiser
                </button>
                <button class="filter-validate" @click="toggle()">Valider</button>
              </div>
            </div>
          </template>
        </Dropdown>

        <Dropdown class="filter">
          <template #wrapper="{ opened }">
            <button :class="{ clicked: opened }" class="filter-button">
              Trier par
              <Icon class="logo" name="ico-sort" />
            </button>
          </template>

          <template #content>
            <div class="content">
              <div
                v-for="sort of SORT_JOURNEY"
                :key="sort.key"
                class="sort"
                :class="{ active: sortName === sort.key && !!sortDirection }"
                @click="sortBy(sort.key)"
              >
                {{ sort.label
                }}<span v-if="sortName === sort.key && !!sortDirection" class="sort-icon"
                  ><Icon :name="`arrow-${sortDirection === 'desc' ? 'down' : 'up'}`"
                /></span>
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
    <div v-if="themes && !isWhereToGo" class="themes">
      <div
        v-for="theme of themes"
        :key="theme.id"
        class="theme"
        :class="{ selected: selectedThemes.includes(theme.attributes.slug) }"
        @click="
          selectedThemes.includes(theme.attributes.slug)
            ? selectedThemes.splice(selectedThemes.indexOf(theme.attributes.slug), 1)
            : selectedThemes.push(theme.attributes.slug)
        "
      >
        <Icon
          v-if="theme.attributes.icon"
          :name="theme.attributes.icon"
          class="logo"
          height="50px"
          width="50px"
        />
        <span>
          {{ theme.attributes.label }}
        </span>
      </div>
    </div>
    <span class="infos-journey-mobile"
      >{{ journeys.length > 0 ? journeys.length - 1 : 'Aucun' }} itinéraires
      {{ isWhereToGo ? 'correspondants' : itineraryName }}</span
    >
    <div v-if="breakpoints.isMobile && openMap">
      <NuxtImg alt="Carte" class="map-mobile" src="map-placeholder.png" />
    </div>
    <div class="wrapper-loading">
      <div v-if="isLoading" class="loading"></div>
    </div>

    <div v-if="!openMap && journeys && !isLoading" class="journeys">
      <JourneyCard
        v-for="journey of journeys.slice(
          0,
          marketingPush?.position !== undefined ? marketingPush.position - 1 : 0
        )"
        :key="journey.id"
        :journey="journey"
        class="journey"
      />
      <SectionPushMarketing v-if="marketingPush" :marketing-push="marketingPush" />
      <JourneyCard
        v-for="journey of journeys.slice(marketingPush?.position ?? 0, journeysShown)"
        :key="journey.id"
        :journey="journey"
        class="journey"
      />
    </div>
    <div v-if="!openMap && journeys.length === 0 && !isLoading" class="no-journeys">
      Aucun produit ne correspond à votre recherche
    </div>

    <div v-if="!openMap" class="conditions">
      <div class="base-price-headline">
        <p v-for="sentence of allBasePriceHeadlines" :key="sentence">{{ sentence }}</p>
      </div>
    </div>

    <button
      v-if="!openMap && journeysShown < journeys.length"
      class="more"
      @click="() => (journeysShown += 8)"
    >
      Voir plus d’itinéraires
    </button>
  </div>
</template>

<script lang="ts" setup>
import { SORT_JOURNEY, BLOCK_JOURNEY_FILTERS } from '@/lib/constants';
import { FilterDropdownModel } from '@/lib/types/models/filter';
import { MvContinent } from '@/lib/strapi-types/MvContinent';
import { MvTheme } from '@/lib/strapi-types/MvTheme';
import { EventContinentsSelector } from '@/lib/types/models/eventComponentMv';
import { MarketingPush } from '@/lib/strapi-types/components/MarketingPush';
import { getBasePriceHeadlineValues } from '@/lib/journey';
import { MvJourney } from '@/lib/strapi-types/MvJourney';

const {
  continents,
  themes,
  itineraryName = ''
} = defineProps<{
  homepage: boolean;
  continents: MvContinent[];
  themes?: MvTheme[];
  itineraryName?: string;
  isWhereToGo?: boolean;
  isSearch?: boolean;
  marketingPush?: MarketingPush;
}>();

const route = useRoute();
const breakpoints = useBreakpoints();

const currentContinentSlug = route.params.continentid;
const checkedFilters = $ref<FilterDropdownModel[]>([]);
const journeysShown = ref(12);
const openMap = $ref(false);
const selectedThemes = $ref<string[]>([]);
const isLoading = ref<boolean>(true);
let sortName = $ref<string | null>(null);
let sortDirection = $ref<string | null>(null);
let selectedContinent = $ref<MvContinent | undefined>(
  continents.find(continent => continent.attributes.slug === currentContinentSlug)
);
let filters = {};

const journeys = ref<MvJourney[]>([]);

if (route.params) {
  if (route.params.continentid) {
    filters = { continents: { slug: route.params.continentid } };
  }
  if (route.params.countryid) {
    filters = {
      countries: { slug: route.params.countryid }
    };
  }
  if (route.params.inspirationsslug) {
    filters = { themes: { slug: route.params.inspirationsslug } };
  }
}

async function fetchJourneys() {
  isLoading.value = true;
  const [{ journeys: journeysData, pending }] = await Promise.all([
    useFindJourneys({
      populate: {
        countries: { fields: ['slug'] },
        continents: { fields: ['slug'] },
        places: { fields: ['label'] }
      },
      fields: ['title', 'price', 'days', 'nights', 'titleImage', 'slug'],
      filters
    })
  ]);

  journeys.value = journeysData.value;
  isLoading.value = pending.value;
}

const filtersStore = useFiltersStore();
const { journeysFilter } = storeToRefs(filtersStore);
watch([journeysFilter, route.params.countryid], fetchJourneys);

const allBasePriceHeadlines = Array.from(
  new Set(
    journeys.value.map(
      journey =>
        `${getBasePriceHeadlineValues(journey?.attributes?.basePriceHeadline ?? 0).stars} ${
          getBasePriceHeadlineValues(journey.attributes.basePriceHeadline ?? 0).sentence
        }`
    )
  )
);
onMounted(() => {
  filtersStore.clearJourneysFilters();
});

allBasePriceHeadlines.sort((a, b) => {
  const aStars = a.split('*').length - 1;
  const bStars = b.split('*').length - 1;
  return aStars - bStars;
});

useHead(() => ({
  script: [
    {
      src: '/@ui/continents-selector.js',
      defer: true
    }
  ]
}));

function sortBy(sortParam: string): void {
  if (sortName === sortParam) {
    sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
  }
  if (!sortDirection) {
    sortDirection = 'desc';
  }

  sortName = sortParam;
}

watch($$([checkedFilters, sortName, sortDirection]), () => {
  const filters = checkedFilters.map(filter => {
    return {
      [filter.label === 'Prix' ? 'price' : 'duration']: {
        min: filter.val[0],
        max: filter.val[1]
      }
    };
  });

  useFiltersStore().setJourneysFilters({
    ...useFiltersStore().journeysFilterParams,
    filters,
    sort: sortName && sortDirection ? `${sortName}:${sortDirection}` : ''
  });
});

watch($$(selectedContinent), () => {
  selectedContinent &&
    useFiltersStore().setJourneysFilters({
      ...useFiltersStore().journeysFilterParams,
      continents: [selectedContinent.attributes.slug]
    });
});

watch(
  $$(selectedThemes),
  () => {
    useFiltersStore().setJourneysFilters({
      ...useFiltersStore().journeysFilterParams,
      themes: selectedThemes
    });
  },
  { deep: true }
);

function chooseContinent(event: EventContinentsSelector) {
  selectedContinent = continents.find(
    continent => continent.attributes.slug === event.detail.continent
  );
}
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/misc.scss';
@use '$/custom-mixins.scss';

input[type='checkbox'] {
  accent-color: colors.$primary-black;
}

.country-infos {
  flex-direction: column;
  align-items: center;
  padding: 60px 0;

  @include breakpoints.mobile() {
    align-items: initial;
    width: 100vw;
    padding: 40px metrics.$mobile-inner-margin;
  }

  .top {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 51px;

    @include breakpoints.mobile() {
      margin-bottom: 0;
    }

    .title {
      width: metrics.$inner-width;

      @include breakpoints.mobile() {
        width: initial;
      }
    }
  }

  .base-price-headline {
    display: block;

    @include breakpoints.mobile() {
      align-items: initial;
    }

    p {
      display: block;
      text-align: left;
    }
  }

  /*  .map {
    width: 100vw;
    height: 424px;
    margin-bottom: 60px;

    object-fit: cover;

    @include breakpoints.mobile() {
      display: none;
    }
  } */

  .infos-filter {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    width: metrics.$inner-width;

    @include breakpoints.mobile() {
      display: flex;
      width: 100%;
    }

    .infos-journey {
      grid-column: 1 / 3;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;

      @include breakpoints.mobile() {
        display: none;
      }
    }

    .filters {
      gap: 20px;
      justify-content: flex-end;

      @include breakpoints.mobile() {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100vw;
      }

      .filter-button {
        @include button.outlined($squared: true, $size: small, $svg-property: 'fill');

        .logo {
          margin-left: 12px;

          @include breakpoints.mobile() {
            display: none;
          }
        }
      }

      .sort {
        cursor: pointer;

        display: flex;
        justify-content: space-between;

        width: 100%;
        padding: 6px;

        border-radius: 3px;

        @include breakpoints.mobile() {
          width: 100px;
        }

        &.active {
          background-color: colors.$grey-500;
        }

        .sort-icon {
          display: flex;
          align-items: center;
        }
      }

      .filter {
        &.filter-left {
          :deep(.content) {
            transform: translate(0);
          }
        }
      }

      .filter .content {
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .filter-active-button {
          display: flex;
          gap: 8px;
          justify-content: space-between;

          .filter-validate {
            @include button.outlined($squared: false, $svg-property: 'fill');

            width: 100px;
            margin-right: 12px;
          }

          .filter-reset {
            display: flex;
            gap: 8px;
            align-items: center;

            text-decoration: underline;
            text-underline-offset: 2px;

            .logo {
              width: 20px;
              height: 20px;
            }
          }
        }

        .filter-name {
          flex-direction: column;
          width: 266px;

          @include breakpoints.mobile() {
            width: 237px;
          }

          .name-value {
            padding-bottom: 12px;
            font-size: 14px;
            font-weight: 600;
          }

          .filter-name-value {
            padding-left: 12px;
            font-size: 18px;
            font-weight: 400;
            color: colors.$primary-black;
          }
        }
      }
    }
  }

  .themes {
    cursor: pointer;
    justify-content: space-between;
    width: metrics.$inner-width;
    margin-top: 40px;

    @include breakpoints.mobile() {
      overflow-x: scroll;
      width: initial;
      margin-top: 22px;
      padding-bottom: 12px;
    }

    .theme {
      flex-direction: column;
      gap: 6px;
      align-items: center;

      max-width: 120px;

      font-size: 16px;
      line-height: 19px;
      color: colors.$black;
      text-align: center;

      @include breakpoints.mobile() {
        min-width: 100px;
        margin-right: 10px;
      }

      .logo {
        width: 50px;

        :deep(svg path) {
          fill: colors.$black;
        }
      }

      &:hover {
        color: colors.$primary-red;

        .logo:deep(svg path) {
          fill: colors.$primary-red;
        }
      }
    }

    .selected {
      color: colors.$primary-red;

      > .logo {
        width: 50px;
        border: 1px solid colors.$primary-red;
        border-radius: 50%;
      }

      .logo:deep(svg path) {
        fill: colors.$primary-red;
      }
    }
  }

  .infos-journey-mobile {
    display: none;

    @include breakpoints.mobile() {
      display: flex;
      flex-direction: column;

      padding-top: 12px;

      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .map-mobile {
    display: block;

    width: 100%;
    height: 424px;
    margin-top: 32px;

    object-fit: cover;
  }

  .wrapper-loading {
    justify-content: center;

    .loading {
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 400px;
      margin: 30px 0;

      /* stylelint-disable-next-line order/order */
      @include misc.loader();
    }
  }

  .journeys {
    @include custom-mixins.mv-grid();

    .journey {
      @include custom-mixins.mv-grid-element();
    }
  }

  .no-journeys {
    align-self: center;

    margin-bottom: 40px;

    font-size: 24px;
    font-weight: 700;
    color: colors.$primary-red;
  }

  .conditions {
    flex-direction: column;
    width: metrics.$inner-width;
    font-size: 16px;
    color: colors.$black;

    @include breakpoints.mobile() {
      width: initial;
    }
  }

  .more {
    @include button.outlined();

    margin-top: 40px;
  }
}
</style>
